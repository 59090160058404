import React from "react";
import OrderPage from "../../components/pages/OrderPage/OrderPage";
import { Meta } from "../../components/header/Meta";

function Index() {
    return (
        <>
            <Meta translateKey="page-index" />
            <OrderPage />
        </>
    );
}

export default Index;
